import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorKamil as author } from 'data/authors';
import img from 'img/blog/covers/form-analytics.png';
import img1 from 'img/blog/formanalytics1.png';
import img2 from 'img/blog/formanalytics2.png';
import Banner from 'components/usability-testing/banner';

const Content = () => {
  return (
    <Post>
      <p>We’ve just added two new filters to help you with form analytics!</p>
      <p>
        The <strong>form submit</strong> filter will help you find sessions where the users
        submitted a particular form. You can search for it with the CSS selector.
      </p>
      <img src={img1} alt="Form submit filter" title="New filter - Form submit" />
      <p>
        With the <strong>form value</strong> filter, you’re able to search for particular values
        submitted in the forms. Depending on your needs, you can choose to filter either by value or
        the CSS selector.
      </p>
      <img src={img2} alt="Form value filter" title="New filter - Form value" />
      <Banner title="Use LiveSession to analyze froms" />
      <p>
        Need more help? Check out our{' '}
        <a href="/tutorials/form-analytics/" target="_blank" rel="noopener noreferrer">
          form analytics tutorial
        </a>
        !
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Form analytics',
  url: '/blog/form-analytics/',
  description: `Introducing two brand new filters: form submit and form value. LiveSession is always here to help you with form analytics.`,
  author,
  img,
  imgSocial: img,
  date: '2020-10-08',
  category: '',
  group: 'updates',
  timeToRead: 1,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
